:root {
	font-size: 1.2rem;
}

* {
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	color: #e4e4e4;
	box-sizing: border-box;
	font-family: "Nunito", sans-serif;
	-webkit-tap-highlight-color: transparent;
}

body {
	display: flex;
	flex-direction: column;
	align-items: center;
}

#mainWrapper {
	max-width: min(100%, 900px);
	padding: 1rem;
}

h1 {

}

#global-status {
	color: #d32f2f;

	&.hide {
		display: none;
	}
}

.status-matrix {

	> div {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: .5rem;
		margin: 1rem 0;
	}
}

:where(.is-available, .most-recent, .sub-status-value) {
	width: 1.5rem;
	height: 1.5rem;
	background-size: cover;

	&.yes {
		background-image: url("img/success.svg");
	}

	&.maybe {
		background-image: url("img/warning.svg");
	}

	&.no {
		background-image: url("img/error.svg");
	}

	&:not(.yes):not(.maybe):not(.no) {
		background-image: url("img/unknown.svg");
	}
}

.row {
	.sub-status {
		display: flex;
		align-items: center;
		gap: .5rem;
		margin-left: 1rem;
		margin-top: .5rem;
		font-size: 0.75rem;

		.sub-status-value {
			width: 0.75rem;
			height: 0.75rem;
		}
	}
}